<template>
    <div class="login_model">
        <div class="login_user_modal">
            <div class="highlight_icon">
                <img src="@/assets/images/highlight.png" alt="">
            </div>
            <div class="go_code_model" @click="goCodeFun">
                <img src="@/assets/images/login_code_icon.png" alt="" v-if="!$store.state.showCodeModel">
                <span v-else>账号登录</span>
            </div>
            <h3 class="login_title">欢迎登录大屏</h3>
            <div v-if="$store.state.showCodeModel">
                <div id="wx_qrcode"></div>
            </div>
            <div class="login_user_sign" v-else>
                <a-form-model 
                    :model="$store.state.loginForm" 
                    ref="ruleForm"
                    :rules='$store.state.loginRules'
                    @submit="handleSubmit" 
                    @submit.native.prevent
                >
                    <a-form-model-item prop="account">
                        <div class="login_item login_account">
                            <div class="login_item_icon">
                                <img src="@/assets/images/user_icon.png" alt="">
                            </div>
                            <a-input v-model="$store.state.loginForm.account" placeholder="请输入账号" />
                        </div>
                    </a-form-model-item>
                    <a-form-model-item prop="password">
                        <div class="login_item login_account">
                            <div class="login_item_icon">
                                <img src="@/assets/images/password_icon.png" alt="">
                            </div>
                            <a-input-password v-model="$store.state.loginForm.password" placeholder="请输入密码" />
                        </div>
                    </a-form-model-item>
                    <a-form-model-item>
                        <div class="reme_word">
                            <a-checkbox class="reme_word_check" v-model="$store.state.keepPassWord">
                                记住密码
                            </a-checkbox>
                        </div>
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-button
                         type="primary"
                          class="login_button"
                          html-type="submit"
                          :loading="$store.state.dispatchValue"
                        >
                            {{ $store.state.dispatchValue ? '登录中…' : '登录' }}
                        </a-button>
                      </a-form-model-item>
                </a-form-model>
                <!-- <div class="login_item login_account">
                    <div class="login_item_icon">
                        <img src="@/assets/images/user_icon.png" alt="">
                    </div>
                    <input type="text" placeholder="请输入账号" v-model="$store.state.loginForm.account">
                </div>
                <div class="login_item login_password">
                    <div class="login_item_icon">
                        <img src="@/assets/images/password_icon.png" alt="">
                    </div>
                    <input :type="!lookPass ? 'password' : 'text'" placeholder="请输入密码" v-model="$store.state.loginForm.passWord">
                    <div class="login_look" @click="lookPassFun">
                        <img v-if="!lookPass" src="@/assets/images/no_look.png" alt="">
                        <img v-else src="@/assets/images/look.png" alt="">
                    </div>
                </div>
                <div class="reme_word">
                    <input type="checkbox">
                    <p>记住密码</p>
                </div>
                <button class="login_button" @click="signIn" :disabled="$store.state.dispatchValue">{{ $store.state.dispatchValue ? '登录中…' : '登录' }}</button> -->
            </div>
        </div>
    </div>
</template>
<script>
    import '@/assets/css/login.less'
    
    export default {
        data() {
            return {
                screenWidth: document.body.clientWidth,
                scale: document.body.clientWidth / 1920,
                lookPass: false
            }
        },
        mounted() {
            // const that = this
            window.onload = function () {
                // // console.log('~~~~~~', this.store.state)
                adjust()
                window.onresize = function () {
                    adjust()
                }
            }
            let adjust = function () {
                let winWidth = document.documentElement.clientWidth
                // // console.log(winWidth)
                let body = document.getElementsByTagName('body')[0]
                // let winHeight = document.body.clientHeight
                // 获取屏幕的宽度 和高度
                let scale = winWidth / 1920
                // // console.log(scale)
                body.style.cssText = 'transform:scale(' + scale + ');'
            }
            this.$store.dispatch('setQyCode')
            this.$store.dispatch('fillDefaultValue')
            // this.$store.dispatch('userSignIn')
        },
        watch: {
            /* 监听*/
            screenWidth(val) {
                this.screenWidth = val;
                // console.log("this.screenWidth", this.screenWidth)
            }
        },
        computed: {
            scaleFun: function () {
                var scale = this.scale;
                return `transform:scale(${scale})`
            },
        },
        methods: {
            lookPassFun () {
                this.lookPass = !this.lookPass
            },
            goCodeFun () {
                this.$store.dispatch('codeChangeValue')
            },
            signIn () {
                this.$store.dispatch('userSignIn')
            },
            handleSubmit () {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.$store.dispatch('userSignIn').then(() => {
                            this.$message.success(
                                '登录成功',
                                1
                            )
                        }).catch(() => {
                            this.$message.error('账号或者密码错误')
                        })
                    }
                })
            }
        }

    }
</script>